import React from "react";
import { MdCircle, MdMoreHoriz } from "react-icons/md";
import Popover from "../../elements/Popover";
import Button from "../../elements/Button";
import Member from "../../elements/Member";
import MemberHeading from "../../elements/MemberHeading";
import ApprovePasswordReset from "./dialogs/ApprovePasswordReset";
import ResendResetPassword from "./dialogs/ResendResetPassword";
import Tag from "../../elements/Tag";
import { useMembers } from "./MembersContext";
import EditUser from "./dialogs/EditUser";
import DeleteUserOption from "./dialogs/DeleteUserOption";

const LockedMembers = () => {
  const {
    byStatus: { locked },
  } = useMembers();

  const passwordResetPending = locked.filter(
    (member) => member.activation_state === "password_reset_pending_approval",
  );
  const passwordResetRequired = locked.filter(
    (member) => member.activation_state === "password_reset_required",
  );

  const renderPendingMembers = () => {
    return passwordResetPending.map((member) => {
      return (
        <Member
          key={member.id}
          member={member}
          locked
          showExpiration
          actionRequired
        >
          <ApprovePasswordReset member={member} />
          <Popover
            trigger={
              <Button variant="tertiary" utilityClasses="br--6 pa--8">
                <MdMoreHoriz size={20} />
              </Button>
            }
            align="start"
            modal={true}
          >
            <div className="display--flex flex--column bg--black br--4">
              <EditUser
                trigger={
                  <Button
                    utilityClasses="pa--16 brbl--4 brbr--4"
                    variant="tertiary"
                  >
                    Edit User
                  </Button>
                }
                member={member}
              />
              <DeleteUserOption member={member} />
            </div>
          </Popover>
        </Member>
      );
    });
  };

  const renderResetRequiredMembers = () => {
    return passwordResetRequired
      .sort(
        (a, b) =>
          new Date(b.invite_expires_at).getTime() -
          new Date(a.invite_expires_at).getTime(),
      )
      .map((member) => {
        return (
          <Member key={member.id} member={member} locked showExpiration>
            <ResendResetPassword member={member} />
            <Popover
              trigger={
                <Button variant="tertiary" utilityClasses="br--6 pa--8">
                  <MdMoreHoriz size={20} />
                </Button>
              }
              align="start"
              modal={true}
            >
              <div className="display--flex flex--column bg--black br--4">
                <EditUser
                  trigger={
                    <Button
                      utilityClasses="pa--16 brbl--4 brbr--4"
                      variant="tertiary"
                    >
                      Edit User
                    </Button>
                  }
                  member={member}
                />
                <DeleteUserOption member={member} />
              </div>
            </Popover>
          </Member>
        );
      });
  };

  const renderMembersList = () => {
    if (locked.length) {
      return (
        <div className="display--flex flex--column gap--16">
          <MemberHeading
            headings={["Name", "Product Access", "Reset Link Expiration Date"]}
          />
          {renderPendingMembers()}
          {renderResetRequiredMembers()}
        </div>
      );
    } else {
      return (
        <div className="display--flex flex--center gap--16 border--dashed bc--disabled br--8">
          <div className="t--disabled">No locked members</div>
        </div>
      );
    }
  };

  const renderPendingActionsTag = () => {
    const count = locked.filter(
      (member) => member.activation_state === "password_reset_pending_approval",
    ).length;

    if (count <= 0) return;

    const text = count === 1 ? "Action Needed" : "Actions Needed";

    return (
      <Tag>
        <MdCircle size={8} color="#e91e63" /> {count} {text}
      </Tag>
    );
  };

  return (
    <section
      id="locked"
      className="users__section users__scroll border--t bc--white pb--32"
    >
      <div>
        <div className="display--flex flex--items-center flex--content-between mb--32">
          <h2 className="fs--24 fw--300 lh--tight">Locked Members</h2>
          {renderPendingActionsTag()}
        </div>
        <p className="fs--12 lh--tight mb--24 w--max-35ch">
          Members who currently do not have access to MOSAIC and are in the
          process of changing their password or are awaiting admin approval.
        </p>
      </div>
      {renderMembersList()}
    </section>
  );
};

export default LockedMembers;
