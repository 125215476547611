import React, { useContext } from "react";
import { MdMoreHoriz } from "react-icons/md";
import Button from "../../elements/Button";
import Member from "../../elements/Member";
import MemberHeading from "../../elements/MemberHeading";
import DeleteUserOption from "./dialogs/DeleteUserOption";
import Popover from "../../elements/Popover";
import EditUser from "./dialogs/EditUser";
import _ from "lodash";
import { useMembers, useMembersToast } from "./MembersContext";
import { postData } from "../../utils/api";
import { UserContext } from "../../UserContext";

const ActiveMembers = () => {
  const { currentUser } = useContext(UserContext);
  const {
    byStatus: { active },
    dispatch,
  } = useMembers();
  const { dispatchToast } = useMembersToast();

  async function handleResetPassword(member) {
    try {
      const response = await postData(
        `/${currentUser.organizationId}/request_password_reset`,
        { id: member.id },
      );
      dispatch({ type: "reset_password", payload: { member: response.user } });
      dispatchToast({
        type: "success_toast",
        payload:
          response.notice || "Password reset initiated. User is now locked.",
      });
    } catch (error) {
      console.log(error);
      dispatchToast({ type: "error_toast" });
    }
  }

  function renderMembers() {
    return _.sortBy(active, (member) => member.name).map((member) => (
      <Member key={member.id} member={member}>
        <EditUser
          trigger={
            <Button variant="secondary" utilityClasses="br--pill ph--12">
              Edit
            </Button>
          }
          member={member}
        />
        <Popover
          trigger={
            <Button
              variant="tertiary"
              utilityClasses="br--6 pa--8"
              data-cy="active-user-popover"
            >
              <MdMoreHoriz size={20} />
            </Button>
          }
          align="start"
        >
          <div className="display--flex flex--column bg--black br--4">
            <Button
              onClick={() => handleResetPassword(member)}
              utilityClasses="pa--16 brtl--4 brtr--4"
              variant="tertiary"
              data-cy="reset-password-button"
            >
              Reset Password
            </Button>
            <DeleteUserOption member={member} />
          </div>
        </Popover>
      </Member>
    ));
  }

  const renderList = () => {
    if (active.length) {
      return (
        <div className="display--flex flex--column gap--16">
          <MemberHeading headings={["Name", "Product Access", "Last Active"]} />
          {renderMembers()}
        </div>
      );
    } else {
      return (
        <div className="display--flex flex--center gap--16 border--dashed bc--disabled br--8 bg--mid-gray-v3">
          <div className="t--disabled">No members</div>
        </div>
      );
    }
  };

  return (
    <section
      className="users__section users__scroll border--t bc--white pb--32"
      id="active"
    >
      <div>
        <h2 className="fs--24 fw--300 lh--tight mb--32">Current Members</h2>
        <p className="fs--12 lh--tight mb--24 w--max-35ch">
          Members who have access to 1 or more apps in the MOSAIC Hub.
        </p>
      </div>
      {renderList()}
    </section>
  );
};

export default ActiveMembers;
