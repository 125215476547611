import React from "react";
import Layout from "../../Layout";
import Header from "./Header";
import Aside from "./Aside";
import MbsCard from "./MbsCard";
import RoundtableEventsCard from "./RoundtableEventsCard";
import RoundtableCollectionCard from "./RoundtableCollectionCard";
import ChargebackSales from "./ChargebackSales";
import TotalSales from "./TotalSales";
import ClaimsByCategory from "./ClaimsByCategory";
import PharmaciesWithPricingAccess from "./PharmaciesWithPricingAccess";
import EoyReportCard from "./EoyReportCard";
import ClaimsByEntities from "./ClaimsByEntities";
import EntitiesWithPricingAccess from "./EntitiesWithPricingAccess";
import SuspendedEntities from "./SuspendedEntities";
import StateExemptions from "./StateExemptions";
import useRunOnce from "../../hooks/useRunOnce";
import * as mp from "mixpanel-browser";

const HomeIndex = (props) => {
  useRunOnce(() => {
    mp.identify(props.user.email);
  }, "logged_in");

  const espUrl = `https://esp.${window.location.host}`;

  function TrackedLinkWrapper({ url = "", tile = "", children }) {
    if (!url) return <>{children}</>;

    return (
      <a href={url} target="_blank" onClick={() => mp.track("Tile Clicked", { "Tile Description": tile })}>
        {children}
      </a>
    );
  }

  const recentCollection = props.rtRoundupCard?.collection;
  const roundtableUrl = props.user.mfrPermissions?.roundtable
    ? `https://roundtable.${window.location.host}${recentCollection?.url}`
    : null;

  return (
    <Layout user={{ ...props.user, hasPendingActions: props.hasPendingActions }} orgId={props.orgId}>
      <Header mfr={props.mfr} />
      <div className="home">
        <div className="home__main pb--4">
          <section className="home__section">
            {props.hasEoyReport && <EoyReportCard />}
            <ChargebackSales />
            <TotalSales />
            <TrackedLinkWrapper url={roundtableUrl} tile="RT Collection">
              <RoundtableCollectionCard recentCollection={recentCollection} />
            </TrackedLinkWrapper>
          </section>
          <section className="home__section">
            <TrackedLinkWrapper url={espUrl} tile="Claims By Category">
              <ClaimsByCategory />
            </TrackedLinkWrapper>
            <TrackedLinkWrapper url={espUrl} tile="Pharmacies With Pricing Access">
              <PharmaciesWithPricingAccess />
            </TrackedLinkWrapper>
            <RoundtableEventsCard events={props.rtEvents} />
            <TrackedLinkWrapper url={espUrl} tile="Claims By Entities">
              <ClaimsByEntities />
            </TrackedLinkWrapper>
          </section>
          <section className="home__section">
            <TrackedLinkWrapper url={props.mbsCard.link} tile="MBS">
              <MbsCard {...props.mbsCard} />
            </TrackedLinkWrapper>
            <TrackedLinkWrapper url={espUrl} tile="Entities With Pricing Access">
              <EntitiesWithPricingAccess />
            </TrackedLinkWrapper>
            <TrackedLinkWrapper url={espUrl} tile="Suspended Entities">
              <SuspendedEntities />
            </TrackedLinkWrapper>
            {props.mfr.cp_exemption_states?.length > 0 && (
              <StateExemptions cpExemptionStates={props.mfr.cp_exemption_states} />
            )}
          </section>
        </div>
        <aside className="home__aside">
          <Aside {...props} />
        </aside>
      </div>
    </Layout>
  );
};

export default HomeIndex;
